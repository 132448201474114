import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { ReactTags } from 'react-tag-autocomplete';
import { debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { notesIcon, checkIcon, addMore } from 'app/assets/Common/commonImages';
import Button from 'app/common/components/Button';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services';
import { request } from 'app/common/utils/request';
import { LoaderContainer } from 'app/pages/Setup/style';
import LoaderComponent from 'app/common/components/Loader';
import EditableTextarea from 'app/common/components/EditableTextarea';

const ReviewNotes = props => {
  const {
    data,
    handleSaveNotes,
    projectLoading,
    notesLoading,
    // endDate,
    // setEndDate,
    cardsData,
    assessment,
    handleSubmit,
    date,
    setDate,
  } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [isNotesDisabled, setIsNotesDisabled] = useState(true);
  const [notesValue, setNotesValue] = useState('');
  const [attendees, setAttendees] = useState({
    users: [],
    suggestions: [],
  });
  const [learningsValue, setLearningsValue] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [sentReportsData, setSentReportsData] = useState([]);
  const startDate = data?.startDate || data?.createdAt;
  const handleSave = () => {
    if (isDisabled) return setIsDisabled(false);
    setIsDisabled(true);
    if (numberValue < 0) {
      toast.error('Number of calls cannot be negative!');
      setIsDisabled(false);
      if (!date?.endDate) {
        toast.error('End Date cannot be empty!');
        setIsDisabled(false);
        return;
      }
      return;
    }
    handleSaveNotes({
      noOfCalls: numberValue,
      attendees: attendees.users?.map(item => item.value),
      endDate: date?.endDate,
      startDate: date?.startDate,
      id: 'project',
    });
  };

  const handleSaveNotesData = () => {
    if (isNotesDisabled) return setIsNotesDisabled(false);
    setIsNotesDisabled(true);
    handleSaveNotes({
      reviewNotes: notesValue,
      learnings: learningsValue,
      id: 'notes',
    });
  };

  const onAdd = useCallback(
    (newTag, type) => {
      setAttendees({
        ...attendees,
        users: [...attendees.users, newTag],
        suggestions: [],
      });
    },
    [attendees],
  );

  const onDelete = useCallback(
    (tagIndex, type) => {
      setAttendees({
        ...attendees,
        users: attendees.users.filter((_, i) => i !== tagIndex),
      });
    },
    [attendees],
  );

  useEffect(() => {
    if (data?.reviewNotes) {
      setNotesValue(data.reviewNotes);
    }
    if (data?.learnings) {
      setLearningsValue(data.learnings);
    }
    if (data?.noOfCalls) setNumberValue(data.noOfCalls);
    // if (data?.endDate) setEndDate(data.endDate);
    if (data?.attendees)
      setAttendees({
        users:
          data.attendees?.map(item => ({
            label: item.username,
            value: item._id,
          })) || [],
        suggestions: [],
      });
    setDate({
      startDate: data?.startDate,
      endDate: data?.endDate,
    });
  }, [data, setDate]);

  useEffect(() => {
    if (data?.sentReports && data?.sentReportToUsers) {
      const combinedReports = data.sentReports.map(report => {
        const toUsers = report.to?.map(userId =>
          data.sentReportToUsers.find(user => user._id === userId),
        );
        const ccUsers = report.cc?.map(userId =>
          data.sentReportCCUsers.find(user => user._id === userId),
        );
        const bccUsers = report.bcc?.map(userId =>
          data.sentReportBCCUsers?.find(user => user._id === userId),
        );

        return {
          ...report,
          toUsers: toUsers || [],
          ccUsers: ccUsers || [],
          bccUsers: bccUsers || [],
        };
      });

      const sortedReports = combinedReports.sort(
        (a, b) => new Date(a.nextSentOn) - new Date(b.nextSentOn),
      );

      const grouped = sortedReports.reduce((acc, report) => {
        const date = moment(report.nextSentOn).format('DD-MMM-YYYY');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(report);
        return acc;
      }, {});

      setSentReportsData(grouped);
    }
  }, [data?.sentReports, data?.sentReportToUsers]);

  const handleTextareaChange = (id, value) => {
    id === 'notes' ? setNotesValue(value) : setLearningsValue(value);
  };

  const searchUsers = searchStr => {
    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL)
      .then(userData => {
        let userDataNew = userData.map(data => {
          return { value: data.id, label: data.username };
        });
        setAttendees({
          ...attendees,
          suggestions: userDataNew,
        });
      })
      .catch(error => {});
  };

  const debouncedSearch = debounce(searchUsers, 500); // 500 milliseconds debounce delay

  const onInput = searchStr => {
    if (searchStr.length === 0 && searchStr.trim().length === 0) {
      return false;
    } else if (searchStr.length < 4 && searchStr.trim().length < 4) {
      return false;
    }
    debouncedSearch(searchStr);
  };

  return (
    <div className="mx-auto mt-[40px] rounded-2xl space-y-8">
      {projectLoading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div
          id="projectDetails"
          className="rounded-2xl custom-card relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#F2FFF4]"
        >
          <div className="flex w-[100%] items-center text-center">
            <div className="w-[100%]">
              <div className="flex w-[100%] text-[#151D48] mb-8">
                <img
                  src={checkIcon}
                  alt="view"
                  className="cursor-pointer h-[24px] mt-2.5"
                />
                <span className="ml-[1.2rem] w-[30%] text-left mt-2 font-semibold ">
                  Project Name:
                </span>
                <span className="mt-2 w-[60%] text-left">{`${data?.project?.projectName}-${data?.trackName}`}</span>
              </div>
              <div className="flex justify-start w-[100%] mb-8">
                <span className="ml-[3.8rem] w-[30%] text-left text-[#151D48] font-semibold ">
                  Initial Review Date:
                </span>
                {/* <span className="w-[60%] text-left">
                  {moment(data?.createdAt).format('DD-MMM-YYYY')}
                </span> */}
                {!isDisabled ? (
                  <div className="date-wrapper-container">
                    <DatePicker
                      dateFormat="dd-MMM-yyyy"
                      selected={date?.startDate || data?.createdAt}
                      onChange={dateVal => {
                        setDate({ ...date, startDate: dateVal });
                      }}
                      // minDate={data?.createdAt}
                      placeholderText="DD - MM - YYYY"
                    />
                  </div>
                ) : (
                  <span className="w-[60%] text-left">
                    {(startDate && moment(startDate).format('DD-MMM-YYYY')) ||
                      '-'}
                  </span>
                )}
              </div>
              <div className="flex justify-start w-[100%] mb-8 endDate">
                <span className="ml-[3.8rem] w-[30%] text-left text-[#151D48] font-semibold ">
                  End Date:
                </span>
                {!isDisabled ? (
                  <div className="date-wrapper-container">
                    <DatePicker
                      dateFormat="dd-MMM-yyyy"
                      selected={date?.endDate}
                      onChange={dateVal => {
                        setDate({ ...date, endDate: dateVal });
                      }}
                      minDate={data?.createdAt}
                      placeholderText="DD - MM - YYYY"
                    />
                  </div>
                ) : (
                  <span className="w-[60%] text-left">
                    {(date?.endDate &&
                      moment(date?.endDate).format('DD-MMM-YYYY')) ||
                      '-'}
                  </span>
                )}
              </div>
              <div className="flex w-[100%] mb-8">
                <span className="ml-[3.8rem] w-[30%] text-left text-[#151D48] font-semibold flex justify-start">
                  Attendees:
                </span>
                <div
                  className=" w-[60%] attendees-container text-left"
                  disabled={!isDisabled}
                >
                  {!isDisabled ? (
                    <ReactTags
                      selected={attendees.users}
                      suggestions={attendees.suggestions}
                      onAdd={data => onAdd(data, 'users')}
                      onDelete={data => onDelete(data, 'users')}
                      onInput={onInput}
                      noOptionsText="No matching emails"
                      minQueryLength={4}
                      placeholderText={''}
                      isDisabled={isDisabled}
                    />
                  ) : (
                    <>
                      {data?.attendees && data.attendees.length > 0 ? (
                        data.attendees.map(item => {
                          return (
                            <p className="user_email inline-block">{`@${item.name}`}</p>
                          );
                        })
                      ) : (
                        <span className="ml-1">-</span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex w-[100%]">
                <span className="ml-[3.8rem] text-left w-[30%] text-[#151D48] font-semibold">
                  Numbers of call conducted:
                </span>
                {!isDisabled ? (
                  <input
                    min="0"
                    type="number"
                    className={`numberInput resize-none py-4 px-5 w-[10%] border border-[#737791] border-solid rounded-2xl outline-none bg-white ${
                      isDisabled ? 'border-dashed' : 'border-solid'
                    }`}
                    name="calls"
                    id=""
                    disabled={isDisabled}
                    value={numberValue}
                    onChange={e => setNumberValue(e.target.value)}
                  />
                ) : (
                  <span className="w-[60%] text-left">
                    {numberValue || '-'}
                  </span>
                )}
              </div>
              <Permission accessTo={USER_ROLES.ADMIN}>
                <div
                  className="flex w-[100%] justify-end"
                  data-html2canvas-ignore="true"
                >
                  <Button
                    handleClick={() => handleSave()}
                    name={isDisabled ? 'Edit' : 'Save'}
                    className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl "
                  />
                </div>
              </Permission>
            </div>
          </div>
        </div>
      )}
      {notesLoading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <Permission accessTo={USER_ROLES.ADMIN}>
          <div className="rounded-2xl custom-card relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#F1F3FD]">
            <div className="flex w-[100%] items-center text-center">
              <img
                src={notesIcon}
                alt="view"
                className="cursor-pointer h-[45px] mt-2.5"
              />
              <h1 className="text-3xl text-[#05004E] font-semibold mb-4 ml-8">
                Notes & Learning
              </h1>
            </div>
            <div className="flex w-[100%] items-center text-center">
              <div className="w-[100%]">
                <div className="flex">
                  <div className="flex flex-col w-[100%] mb-8 ml-[6.8rem]">
                    <span className=" text-[#151D48] font-semibold w-[20%] text-left">
                      Notes:
                    </span>
                    {!isNotesDisabled ? (
                      <EditableTextarea
                        type="text"
                        name="notes_textarea"
                        value={notesValue}
                        label="Remarks"
                        className={'w-full add-remarks '}
                        onInputChange={event => {
                          handleTextareaChange(
                            'notes',
                            event.currentTarget.innerHTML || '',
                          );
                        }}
                        disabled={isNotesDisabled}
                      />
                    ) : (
                      <span className="w-[100%] text-left">
                        {notesValue || '-'}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col w-[100%] mb-8 ml-[3rem]">
                    <span className=" text-[#151D48] font-semibold w-[20%] text-left">
                      Learnings:
                    </span>
                    {!isNotesDisabled ? (
                      <EditableTextarea
                        type="text"
                        name="learnnings_textarea"
                        value={learningsValue}
                        label="Remarks"
                        className={'w-full add-remarks '}
                        onInputChange={event => {
                          handleTextareaChange(
                            'learnings',
                            event.currentTarget.innerHTML || '',
                          );
                        }}
                        disabled={isNotesDisabled}
                      />
                    ) : (
                      <span className="w-[100%] text-left">
                        {learningsValue || '-'}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex w-[100%] justify-end">
                  <Button
                    handleClick={() => handleSaveNotesData()}
                    name={isNotesDisabled ? 'Edit' : 'Save'}
                    className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl "
                  />
                </div>
              </div>
            </div>
          </div>
        </Permission>
      )}
      <Permission accessTo={USER_ROLES.ADMIN}>
        <div className="flex">
          {sentReportsData && Object.keys(sentReportsData).length > 0 && (
            <div className="rounded-2xl send-report-card relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#F1F3FD] mr-[3rem]">
              <div className="flex w-[100%] items-center text-center">
                <img
                  src={notesIcon}
                  alt="view"
                  className="cursor-pointer h-[45px] mt-2.5"
                />
                <h1 className="text-3xl text-[#05004E] font-semibold mb-4 ml-8">
                  Sent Report
                </h1>
              </div>
              <div className="flex w-[100%] flex-col ml-[20px]">
                {/* <h3 className="ml-[6.7rem] text-[#DE1186]">Report Sent on</h3>
          <div className="flex">
            {sentReportsData?.map(report => (
              <div key={report.nextSentOn} className="mb-8">
                <span className="ml-[6.7rem] text-[#151D48] font-semibold">
                  {moment(report.nextSentOn).format('DD-MMM-YYYY')}
                </span>
                <span className="ml-[2rem] text-[#151D48]">
                  {report.users.map(user => user.name).join(', ')}
                </span>
              </div>
            ))}
          </div> */}
                <h3 className=" text-[#DE1186] mb-[3rem] ml-[45px]">
                  Report Sent on
                </h3>
                {/* {Object.keys(sentReportsData).map((date, index) => (
            <div
              key={date}
              className="pb-[3rem] relative border-l border-solid border-1 border-[#111]"
            >
              <div className="custom absolute left-[-13px] w-[26px] h-[26px] leading-[1.6] text-center text-[#FFFFFF] rounded-[50%] bg-[#DE1186] border border-solid border-1 border-[#FFFFFF]">
                <span>{index + 1}</span>
              </div>
              <span className="ml-[4rem] text-[#151D48] font-semibold">
                {date}
              </span>
              <span className="ml-[4rem] text-[#151D48] flex">
                {sentReportsData[date].map(user => (
                  <div
                    className="p-3 bg-[#D2D5E4] rounded-lg ml-2"
                    key={user._id}
                  >
                    <span>@{user.name}</span>
                  </div>
                ))}
              </span>
            </div>
          ))} */}
                {/* {Object.keys(sentReportsData).map((date, index) => {
            const isLastElement =
              index === Object.keys(sentReportsData).length - 1;
            return (
              <div
                key={date}
                className={`pb-[3rem] relative ${
                  !isLastElement
                    ? 'border-l border-solid border-1 border-[#111]'
                    : ''
                }`}
              >
                <div className="custom absolute left-[-13px] w-[26px] h-[26px] leading-[1.8] text-center text-[#FFFFFF] rounded-[50%] bg-[#DE1186] border border-solid border-1 border-[#FFFFFF]">
                  <span>{index + 1}</span>
                </div>
                <span className="ml-[4rem] text-[#151D48] font-semibold">
                  {date}
                </span>
                <span className="ml-[4rem] text-[#151D48] flex">
                  {sentReportsData[date].map(user => (
                    <div
                      className="p-3 bg-[#D2D5E4] rounded-lg ml-2"
                      key={user._id}
                    >
                      <span>@{user.name}</span>
                    </div>
                  ))}
                </span>
              </div>
            );
          })} */}
                {Object.keys(sentReportsData).map((date, index) => {
                  const toUsers = sentReportsData[date]
                    .flatMap(report => report.toUsers)
                    .filter(
                      (value, index, array) => array.indexOf(value) === index,
                    );

                  const ccUsers = sentReportsData[date]
                    .flatMap(report => report.ccUsers || [])
                    .filter(
                      (value, index, array) => array.indexOf(value) === index,
                    );
                  const bccUsers = sentReportsData[date]
                    .flatMap(report => report.bccUsers || [])
                    .filter(
                      (value, index, array) => array.indexOf(value) === index,
                    );

                  return (
                    <div
                      className="rounded-2xl custom-card relative p-10 pb-12 pt-6 block"
                      key={index}
                    >
                      <div className="custom absolute left-[-13px] w-[26px] h-[26px] leading-[1.8] text-center text-[#FFFFFF] rounded-[50%] bg-[#DE1186] border border-solid border-1 border-[#FFFFFF]">
                        <span>{index + 1}</span>
                      </div>
                      <div className="flex w-[100%] items-center text-center">
                        <div className="w-[100%]">
                          <div className="flex justify-start w-[100%] mb-8 pt-[4px]">
                            <span className="w-[30%] text-left text-[#151D48] font-semibold">
                              {date}
                            </span>
                          </div>
                          <div className="flex w-[100%] mb-8">
                            <span className="text-left text-[#151D48] font-semibold flex justify-start">
                              To:
                            </span>
                            <div className="text-left">
                              {toUsers && toUsers.length > 0 ? (
                                toUsers.map(user => (
                                  <span className="p-3 bg-[#D2D5E4] rounded-lg ml-3 inline-block mb-[4px]">
                                    @{user?.name}
                                  </span>
                                ))
                              ) : (
                                <span className="ml-3">-</span>
                              )}
                            </div>
                          </div>
                          {ccUsers && ccUsers.length > 0 && (
                            <div className="flex w-[100%] mb-8">
                              <span className="text-left text-[#151D48] font-semibold flex justify-start">
                                CC:
                              </span>
                              <div className="text-left">
                                {ccUsers.map(user => (
                                  <span className="p-3 bg-[#D2D5E4] rounded-lg ml-3 inline-block mb-[4px]">
                                    @{user?.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )}
                          {bccUsers && bccUsers.length > 0 && (
                            <div className="flex w-[100%] mb-8">
                              <span className="text-left text-[#151D48] font-semibold flex justify-start">
                                BCC:
                              </span>
                              <div className="text-left">
                                {bccUsers.map(user => (
                                  <span className="p-3 bg-[#D2D5E4] rounded-lg ml-3 inline-block mb-[4px]">
                                    @{user?.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {
            <div className="rounded-2xl relative p-10 pb-12 pt-6 shadow-md flex-1 block bg-[#F1F3FD]">
              {cardsData.map((card, index) =>
                card.content || card.link ? (
                  <>
                    <div key={`${card.id}-${index}`} className="custom-card ">
                      <h1 className="text-2xl text-[ #151D48] font-semibold mb-4">
                        {card.title}
                      </h1>
                      {card.title === 'Upcoming Events' && (
                        <div className="upcomingEventHeading">
                          <p>Next follow-up date</p>
                        </div>
                      )}
                      {Array.isArray(card.content)
                        ? card.content.map((item, index) => {
                            if (card.id === 'upcomingEvents') {
                              return (
                                <>
                                  <div
                                    className="eventsDatesContainer"
                                    style={
                                      (card.content.length - 1 === index &&
                                        assessment?.assessmentDetails
                                          ?.trackStatus !== 'CLOSED') ||
                                      card.content.length !== index + 1
                                        ? { borderLeft: '1px solid #111' }
                                        : {}
                                    }
                                  >
                                    <span className="firstEventNumber">
                                      {index + 1}
                                    </span>
                                    <p className="text-gray-700">
                                      {moment(item.nextFollowUp).format(
                                        'DD-MMM-YYYY',
                                      )}
                                    </p>
                                  </div>
                                  {card.content.length - 1 === index &&
                                    assessment?.assessmentDetails
                                      ?.trackStatus !== 'CLOSED' && (
                                      <span
                                        className="eventAddMore cursor-pointer"
                                        onClick={handleSubmit}
                                      >
                                        <img src={addMore} alt="addMore-btn" />{' '}
                                        <p>Add More</p>
                                      </span>
                                    )}
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <p className="text-base">
                                    The project has been closed by{' '}
                                    {moment(item.closingDate).format(
                                      'Do MMMM, YYYY',
                                    )}
                                  </p>
                                  <p className="text-black-700 py-6 ">
                                    {item.closingNotes}
                                  </p>
                                </>
                              );
                            }
                          })
                        : card.content && (
                            <p className="text-gray-700">{card.content}</p>
                          )}
                      {card.content.length < 1 &&
                        card.title === 'Upcoming Events' && (
                          <span
                            className="eventAddMore cursor-pointer"
                            onClick={handleSubmit}
                          >
                            <img src={addMore} alt="addMore-btn" />{' '}
                            <p>Add More</p>
                          </span>
                        )}
                      {card.link && (
                        <a
                          href={card.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline mt-2 block"
                        >
                          {card.link}
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                ),
              )}
            </div>
          }
        </div>
      </Permission>
    </div>
  );
};

export default ReviewNotes;
