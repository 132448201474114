export const isEmpty = value => {
  if (value === null || value === undefined) {
    return true;
  }
  if (
    typeof value === 'number' ||
    typeof value === 'string' ||
    Array.isArray(value)
  ) {
    return !value.length;
  }
  if (typeof value === 'object') {
    return !Object.keys(value).length;
  }
  return false;
};

export const slug = inputString => {
  return inputString?.toLowerCase().replace(/\s+/g, '-');
};

export const revertSlug = slug => {
  if (slug) {
    const parts = slug.split('-');
    const revertedString = parts
      .map(part => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
    return revertedString;
  } else {
    return null;
  }
};

// export const handleDotsClick = (event, id) => {
//   event.stopPropagation();
//   Array.from(document.getElementsByClassName('three-dots')).forEach(
//     (element, index) => {
//       if (id - 1 !== index && !element.classList.contains('hidden')) {
//         element.classList.add('hidden');
//       }
//     },
//   );
//   if (document.getElementById(id).classList.contains('hidden')) {
//     document.getElementById(id).classList.remove('hidden');
//   } else {
//     document.getElementById(id).classList.add('hidden');
//   }
// };
export const handleDotsClick = (event, id) => {
  event.stopPropagation();
  const clickedElement = document.getElementById(id);

  Array.from(document.getElementsByClassName('three-dots')).forEach(element => {
    if (element !== clickedElement && !element.classList.contains('hidden')) {
      element.classList.add('hidden');
    }
  });

  clickedElement.classList.toggle('hidden');
};

export const addDotsEventListener = () => {
  document.addEventListener('click', () => {
    Array.from(document.getElementsByClassName('three-dots')).map(element => {
      if (!element.classList.contains('hidden')) {
        element.classList.add('hidden');
      }
    });
  });
};

export const removeDotsEventListener = () => {
  document.removeEventListener('click', () => {
    Array.from(document.getElementsByClassName('three-dots')).map(element => {
      if (!element.classList.contains('hidden')) {
        element.classList.add('hidden');
      }
    });
  });
};

export const deepClone = obj => {
  let clone = JSON.parse(JSON.stringify(obj));
  return clone;
};
