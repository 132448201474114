import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './table.css';

function Table({
  data,
  columns,
  className = '',
  defaultArray = [],
  needPagination = false,
  currentPage,
  onPageChange,
}) {
  const paginationOptions = {
    page: currentPage,
    sizePerPage: 10, // Should match `rowsPerPage`
    totalSize: data.length,
    hideSizePerPage: true,
    onPageChange: page => {
      onPageChange(page);
    },
  };

  return (
    <>
      <div className={className}>
        <BootstrapTable
          keyField="_id"
          data={data}
          columns={columns}
          bordered={false}
          defaultSorted={defaultArray}
          pagination={
            needPagination && data?.length > 10
              ? paginationFactory(paginationOptions)
              : null
          }
        />
      </div>
    </>
  );
}

export default Table;
