import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ActiveName, NavLinkContainer, PageIcon } from './style';
import { useDispatch } from 'react-redux';
import { useProjectSlice } from 'app/pages/Projects/projectSlice';

function NavLink(props) {
  const { pageIcon, activePageIcon, pageName, pageLink, sidebarOpen, title } =
    props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useProjectSlice();

  const activePage =
    (pageLink !== '/' && location.pathname.includes(pageLink)) ||
    (pageLink === '/' && location.pathname === pageLink);

  const resetProjectsPage = () => {
    dispatch(actions.resetState());
    dispatch(
      actions.getProjects({
        items: 20,
        page: 1,
        search: '',
      }),
    );
  };
  return (
    <>
      <NavLinkContainer
        active={activePage}
        disabled={pageName === 'Settings'}
        onClick={() => {
          if (location.pathname.includes('/projects')) {
            resetProjectsPage();
          }
          navigate(pageLink);
        }}
      >
        <PageIcon
          style={{ marginRight: sidebarOpen ? '0' : '20px' }}
          title={title}
        >
          {activePage && activePageIcon ? activePageIcon : pageIcon}
        </PageIcon>
        <ActiveName active={activePage}>{pageName}</ActiveName>
      </NavLinkContainer>
    </>
  );
}

export default NavLink;
