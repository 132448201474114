import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import videoready from 'app/assets/videoready.svg';
import {
  employeelogo,
  accounts,
  defaultUser,
  arrow,
} from 'app/assets/Common/commonImages';
import Breadcrumb from 'app/common/components/Breadcrumb';
// import Dropdown from '../common/dropDown';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';
import { slug } from 'app/common/utils/helperFn';
import { useReviewSlice } from 'app/pages/Review/reviewSlice';
import './style.css';

const viewRoute = 'review/account';

const Index = props => {
  const params = useParams();
  // const options = ['OTT DEV', 'CLOUD_KEEPER', 'DIGITAL_MARKETING'];
  // const [selectedOptions, setSelectedOption] = useState(options[0]);

  // const handleSelects = option => {
  //   setSelectedOption(option);
  // };
  const [data, setData] = useState([]);
  const [buData, setBuData] = useState([]);

  const { actions } = useReviewSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getBusinessUnitDetails(params.id));
    }
  }, [params?.id]);

  const reviewResponse = useSelector(selectReview);
  const { businessUnitDetails, loading } = reviewResponse;

  useEffect(() => {
    if (businessUnitDetails) {
      const details = businessUnitDetails;

      const buHeadDetailsData = details.buHeadDetails?.map(buHead => ({
        heading: buHead.name,
        subheading: buHead.designation,
        image: buHead.profilePicURL ?? defaultUser,
        imageMargin: '32px',
        height: '80px',
        borderRadius: '50px',
      }));
      setBuData(buHeadDetailsData);
      setData([
        {
          heading: details?.totalNumberOfProject,
          subheading: 'Projects',
          image: employeelogo,
          imageMargin: '57px',
        },
        {
          heading: details?.totalNumberOfClients,
          subheading: 'Accounts',
          image: employeelogo,
          imageMargin: '57px',
        },
        {
          heading: details?.totalNumberofEmployees,
          subheading: 'Employees',
          image: accounts,
          imageMargin: '57px',
        },
        // {
        //   heading: '4.5',
        //   subheading: 'Overall Rating',
        //   image: rating,
        //   imageMargin: '50px',
        // },
      ]);
    }
  }, [businessUnitDetails, params?.id]);

  const businessUserDetails =
    businessUnitDetails?.accounts && businessUnitDetails?.accounts.length
      ? businessUnitDetails?.accounts?.map(account => ({
          id: account?._id,
          title: account?.accountName,
          background: '#F1F3FD',
          logo: videoready,
          projects: account?.projectCount,
          reviews: account?.reviews,
        }))
      : [];

  const renderBuDetails = (item, index) => (
    <div key={index} className="flex px-4">
      <div className="bu-slider bg-white rounded-full p-2 w-[3.5vw] h-[3.5vw] flex justify-center items-center">
        {item?.image ? (
          <img
            src={item?.image}
            alt="info-mage"
            className=" w-[3vw] h-[3vw] rounded-full "
          />
        ) : (
          ''
        )}
      </div>
      <div className="flex flex-col justify-center ml-4">
        <span className="title font-bold text-xl">{item?.heading}</span>
        <span className="desctiption text-base">{item?.subheading}</span>
      </div>
    </div>
  );

  const navigate = useNavigate();

  const handleViewClick = (cardId, cardName, buName) => {
    const encodedUrl = `/${slug(viewRoute)}/${cardId}`;
    navigate(encodedUrl);
  };

  // const decodedHeading = revertSlug(params.name);
  const breadcrumbItems = [
    { text: 'Review', link: '/review' },
    { text: businessUnitDetails?.businessUnitName },
  ];
  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="flex items-center justify-between mb-8">
            <Breadcrumb
              heading={businessUnitDetails?.businessUnitName}
              items={breadcrumbItems}
            />
            {/* <Dropdown
              options={options}
              onSelect={handleSelects}
              className="drp-1 border-[#737791]"
              selectedOption={selectedOptions}
              optionsClassName="custom-options1"
              placeholder="Select"
              isDisabled={true}
            /> */}
          </div>
          <div className="flex bg-[#f1f3fd] py-8 rounded-2xl justify-around">
            {buData?.map((item, index) => renderBuDetails(item, index))}
            {data.map((item, index) => {
              // const isLastItem = index === data?.length - 1;
              return (
                <div key={index} className="flex px-4 border-l-2 border-white">
                  <div className="bu-slider bg-white p-6 w-[3.5vw] h-[3.5vw] flex justify-center items-center rounded-full">
                    <img src={item?.image} alt="info-mage" className=" w-12" />
                  </div>
                  <div
                    className={`flex flex-col justify-center ml-4`}
                    // style={{
                    //   color: isLastItem ? 'lightgrey' : '',
                    // }}
                  >
                    <span className="title font-bold text-xl">
                      {item?.heading}
                    </span>
                    <span className="description text-base">
                      {item?.subheading}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {businessUserDetails.length ? (
            <>
              <h2 className="mt-16 text-3xl font-bold mb-8">
                All Active Accounts ({businessUserDetails?.length})
              </h2>
              {/*----- LISTING ACCOUNT CARDS ---------*/}
              {/* <div className="w-full flex flex-wrap justify-start"> */}
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8">
                {businessUserDetails?.map(account => {
                  return (
                    <div
                      key={account?.id}
                      // className="card-layout flex flex-col mt-8 mr-8 bg-[#f1f3fd] rounded-3xl"
                      className="card-layout"
                    >
                      <div
                        className={`w-[125px] h-[75px] bg-white rounded-3xl shadow-md p-6 mb-[20px]`}
                      ></div>
                      <span className="text-2xl font-semibold">
                        {account?.title}
                      </span>
                      <span className="text-xl mt-4 font-medium">
                        {account?.projects} Projects
                      </span>
                      {/* <div className="view-button w-[30rem] mt-12 flex justify-end items-center absolute bottom-0 pb-9 pr-5"> */}
                      <div className="mt-12 absolute bottom-0 right-0 pb-9 mr-[8px]">
                        {account?.reviews ? (
                          <span className="bg-[#F28300] rounded-lg text-white font-medium p-1 px-3 text-base">
                            {account?.reviews}
                          </span>
                        ) : (
                          ''
                        )}
                        <button
                          className="flex items-center"
                          onClick={() =>
                            handleViewClick(
                              account?.id,
                              account?.title,
                              params.name,
                            )
                          }
                        >
                          <span className="text-[#de1186] font-medium text-2xl hover:cursor-pointer">
                            View
                          </span>
                          <img
                            src={arrow}
                            alt="Arrow"
                            className="hover:cursor-pointer"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            'No Active account'
          )}
        </div>
      )}
    </>
  );
};
export default Index;
