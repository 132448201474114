import {
  checkIcon,
  // questionIcon,
  cancelIcon,
  mediumIcon,
  highIcon,
  lowIcon,
} from 'app/assets/Common/commonImages';

export const API_CONSTANTS = {
  AUTH_USER: '/auth/user',
  DESTROY_AUTH: 'auth/logout',
  LOGIN_AUTH: '/auth/login',
  PARAMETERS: '/parameters', // GET PARAMETER
  PARAMETER: '/parameter', // DELETE PARAMETER
};

export const API_ERROR_CODES = {
  CategoryUpdateNotAllowed: 'CategoryUpdateNotAllowed',
  CategoryDeleteNotAllowed: 'CategoryDeleteNotAllowed',
  TrackDeleteNotAllowed: 'TrackDeleteNotAllowed',
  ForbiddenException: 'ForbiddenException',
};

export const TRACK_STATUS = {
  YET_TO_START: 'Yet To Start',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const TRACK_STATUS_NEW = [
  { value: 'YET_TO_START', label: 'Yet To Start' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'COMPLETED', label: 'Completed' },
];
export const ROLES = [
  { value: 'SUPER_ADMIN', label: 'Super Admin' },
  { value: 'ADMIN', label: 'Admin' },
  // { value: 'MANAGER', label: 'Manager' },
  { value: 'VIEWER', label: 'Viewer' },
];

export const SKILL_ROLES = [
  { value: 'SUPER_ADMIN', label: 'Super Admin' },
  { value: 'APPROVER', label: 'Approver' },
  { value: 'CREATOR', label: 'Creator' },
  { value: 'VIEWER', label: 'Viewer' },
];

export const SKILL_STATUS = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
];

export const PROJECT_START_YEAR = 2023; //initial value set hardcoded as no projects will be initiated before 2023

export const CALENDAR_HEIGHT = 500;

export const suggestionsStatusCriteria = [
  { value: 'COMPLIANT', label: 'Compliant' },
  { value: 'PARTIALLY_COMPLIANT', label: 'Partially Compliant' },
  { value: 'NOT_COMPLIANT', label: 'Not Compliant' },
  { value: 'RED', label: 'Red' },
  { value: 'AMBER', label: 'Amber' },
  { value: 'GREEN', label: 'Green' },
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
  { value: 'PARTIALLY', label: 'Partially' },
];

export const impactOptions = [
  { value: 'high', label: highIcon },
  { value: 'medium', label: mediumIcon },
  { value: 'low', label: lowIcon },
];

export const findingOptions = [
  { value: 'yes', label: checkIcon },
  { value: 'no', label: cancelIcon },
  // { value: 'maybe', label: questionIcon },
];

export const projectStatusOptions = [
  { value: '', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
export const REVIEW_STATUS = [
  { value: '', label: 'All' },
  {
    value: 'YET_TO_START',
    label: 'Yet To Start',
    className: 'border-[#F28300] text-[#F28300] bg-[#F8F8F8]',
  },
  {
    value: 'IN_PROGRESS',
    label: 'In Progress',
    className: 'border-[#FFD700] text-[#ac9000] bg-[#F8F8F8]',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
    className: 'border-[#00CE81] text-[#00CE81] bg-[#F0FDF4]',
  },
];
