export const defaultEmailContent = `<p>Hi {to},
  <br/>
  <br/>
  Hope you are doing well!
  <br/>
  <br/>
  Thank you for your time and support during the project governance review activity.
  <br/>
  <br/>
  Here is the {report} with information captured along with recommendations from a process and technical side.
  <br/>
  <br/>
  The report is attached too, in case you are unable to access the link.
  <br/>
  <br/>
  <br/><b>As a next step:</b><br/>
  <ul>
    <li>The Project Governance team will schedule a call for a walkthrough of the report.</li>
    <li>Prioritize the recommendations and curate an action plan around the same.</li>
    <li>Update recommendations on the critical review deck of the project on your existing slide.</li>
  </ul>
  <br/>
  We would be setting up a follow-up discussion after 3 months to check for any additional things that would have come up or any support needed.
  <br/>
  <br/>
  Please feel free to reach out should you have any questions.`;
