//Homepage Cards

import arrow from '../../assets/arrow.svg';
import star from '../../assets/staricon.svg';
import ratingIcon from '../../assets/ratingicon.svg';
// Review Rating
import employeelogo from '../../assets/employeelogo.svg';
import accounts from '../../assets/accounts.svg';
// import defaultUser from '../../pages/HomePage/assets/userimagedefault.svg';
import defaultUser from '../../assets/userDefaultImg.svg';
//review page
import tableview from '../../assets/tableview.svg';
import threeDots from '../../assets/Icons/three-dots.png';
import tataplay from '../../assets/project.png';
import stars from '../../assets/star.svg';
import leftArrow from '../../assets/Icons/left-arrow-blue.png';
//review Completion page
import infoImg from '../../assets/Icons/info-icon.png';
//reviewlisting page
import middleast from '../../assets/middleast.svg';
//setupform page
import error from '../../assets/error.svg';
//accordion
import frame from '../../assets/frame.svg';
import trash from '../../assets/trash.svg';
import uparrow from '../../assets/uparrow.svg';
import Sign from '../../assets/plus-sign.svg';
//Dashboard cardsnavbar
import multiview from '../../assets/multiview.svg';
import chartview from '../../assets/chartview.svg';
import gridview from '../../assets/gridview.svg';
import exporticon from '../../assets/export-icon.svg';
import multiviewactive from '../../assets/multiviewactive.svg';
import gridviewactive from '../../assets/gridviewactive.svg';
import chartviewactive from '../../assets/chartviewactive.svg';
//dropdown
import dropdown from '../../assets/dropdown.svg';
//createnew
import createNew from '../../assets/createnew.svg';
//modalmanager
import image from '../../assets/Icons/cross.png';
import errorImg from '../../assets/Icons/errorImg.svg';
// dashboard calendar
import calendarIcon from '../Icons/calendar-icon.svg';
import calendarIconD from '../Icons/calendar-icon-d.svg';
import listIcon from '../Icons/list-icon.svg';
import listIconD from '../Icons/list-icon-d.svg';
import infoSmallImage from '../Icons/information.svg';
import event from '../Icons/calendareventa.png';
import eventd from '../Icons/calendareventd.png';
import deleteIcon from '../Icons/deleteIcon.svg';
import addMore from '../Icons/addMore.svg';
import editIcon from '../Icons/edit.svg';
import saveIcon from '../Icons/save.svg';
//sidebar
import { ReactComponent as DashboardIcon } from '../../assets/dashboard-icon.svg';
import { ReactComponent as DashboardIconActive } from '../../assets/dashboard-icon-active.svg';
import { ReactComponent as ReviewIcon } from '../../assets/review-icon.svg';
import { ReactComponent as ReviewIconActive } from '../../assets/review-icon-active.svg';
import { ReactComponent as SetupIcon } from '../../assets/setup-icon.svg';
import { ReactComponent as SetupIconActive } from '../../assets/setup-icon-active.svg';
import { ReactComponent as PermissionIcon } from '../../assets/roles.svg';
import { ReactComponent as PermissionIconActive } from '../../assets/roles-active.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg';
import { ReactComponent as ProjectIcon } from '../../assets/projects.svg';
import { ReactComponent as ProjectIconActive } from '../../assets/projectsactive.svg';

import iconBlue from '../Icons/Icon-blue.svg';
import editDashed from '../Icons/editDashed.svg';
import checkIcon from '../Icons/check.svg';
import cancelIcon from '../Icons/cancel.svg';
import questionIcon from '../Icons/question.svg';
import highIcon from '../Icons/High.svg';
import lowIcon from '../Icons/low.svg';
import mediumIcon from '../Icons/medium.svg';
import notesIcon from '../Icons/notes.svg';
import leftArrowIcon from '../Icons/leftArrow.svg';
import rightArrowIcon from '../Icons/rightArrow.svg';
export {
  arrow,
  star,
  ratingIcon,
  employeelogo,
  accounts,
  defaultUser,
  tableview,
  threeDots,
  tataplay,
  stars,
  leftArrow,
  infoImg,
  middleast,
  error,
  frame,
  trash,
  uparrow,
  Sign,
  multiview,
  chartview,
  gridview,
  exporticon,
  multiviewactive,
  gridviewactive,
  chartviewactive,
  dropdown,
  createNew,
  image,
  errorImg,
  calendarIcon,
  calendarIconD,
  listIcon,
  listIconD,
  infoSmallImage,
  event,
  eventd,
  DashboardIcon,
  DashboardIconActive,
  ReviewIcon,
  ReviewIconActive,
  SetupIcon,
  SetupIconActive,
  PermissionIcon,
  PermissionIconActive,
  ProjectIcon,
  ProjectIconActive,
  SettingsIcon,
  deleteIcon,
  addMore,
  editIcon,
  saveIcon,
  iconBlue,
  editDashed,
  checkIcon,
  cancelIcon,
  questionIcon,
  highIcon,
  lowIcon,
  mediumIcon,
  notesIcon,
  leftArrowIcon,
  rightArrowIcon,
};
