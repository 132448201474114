import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { dropdown } from '../../../assets/Common/commonImages';

function Dropdown({
  options,
  onSelect,
  selectedOption,
  placeholder,
  className,
  optionsClassName,
  isDisabled = false,
  appendedText = [],
  form = '',
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const selectedOptionObj = options.find(
    option => option.value === selectedOption,
  );

  const handleToggle = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = option => {
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={className}>
      <div
        className={`selected-option flex justify-between items-center cursor-pointer ${
          isDisabled ? 'disabled' : ''
        }`}
        onClick={handleToggle}
      >
        <span
          className={`selected-text ${
            !selectedOptionObj?.label ? 'opacity-50' : ''
          }`}
        >
          {form !== 'recommendations' &&
            form !== 'keyfindings' &&
            (selectedOptionObj?.label || placeholder)}
          {(form === 'recommendations' || form === 'keyfindings') && (
            <img
              src={selectedOptionObj?.label}
              alt={selectedOptionObj?.label}
            />
          )}
        </span>
        <span className={`caret ${isOpen ? 'rotate' : ''}`}>
          <img src={dropdown} alt="Collapse" />
        </span>
      </div>
      {!isDisabled && isOpen && (
        <ul className={`options ${optionsClassName}`}>
          {options.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option.value)}>
              {form !== 'recommendations' &&
                form !== 'keyfindings' &&
                option.label}
              {(form === 'recommendations' || form === 'keyfindings') && (
                <img src={option.label} alt={option.label} />
              )}
              {appendedText && appendedText[index] && (
                <span className="text-[#151D48] opacity-50">
                  {' '}
                  ({appendedText[index]})
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  placeholder: PropTypes.string,
};
