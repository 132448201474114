import { createSlice } from 'app/common/utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from 'app/common/utils/redux-injectors';
import { projectSaga } from './saga';

export const initialState = {
  view: 'Projects',
  error: null,
  loading: false,
  searchQuery: '',
  projectsData: [],
  totalCount: 0,
  count: 0,
  activeCount: 0,
  inactiveCount: 0,
  reviewInProgressCount: 0,
  reviewedCount: 0,
  currentPage: 1,
  selectedStatusFilter: '',
  selectedReviewFilter: '',
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload.query;
      state.currentPage = 1;
    },
    setProjectFilterStatus(state, action) {
      state.currentPage = 1;
      state.projectsData = [];
      state.selectedStatusFilter = action.payload;
    },
    setReviewFilterStatus(state, action) {
      state.currentPage = 1;
      state.projectsData = [];
      state.selectedReviewFilter = action.payload;
    },
    getProjects(state) {
      state.loading = true;
    },
    gotProjects(state, action) {
      const {
        data,
        count,
        activeCount,
        totalCount,
        inactiveCount,
        reviewInProgressCount,
        reviewedCount,
      } = action.payload;
      if (state.currentPage === 1) {
        state.projectsData = data;
      } else {
        state.projectsData = [...state.projectsData, ...data];
      }
      state.count = count;
      state.totalCount = totalCount;
      state.activeCount = activeCount;
      state.inactiveCount = inactiveCount;
      state.reviewInProgressCount = reviewInProgressCount;
      state.reviewedCount = reviewedCount;
      state.loading = false;
    },
    setPage(state, action) {
      state.currentPage = action.payload;
    },
    removeLoader(state) {
      state.loading = false;
    },
    resetState(state) {
      state.projectsData = initialState.projectsData;
      state.totalCount = initialState.totalCount;
      state.count = initialState.count;
      state.activeCount = initialState.activeCount;
      state.inactiveCount = initialState.inactiveCount;
      state.reviewInProgressCount = initialState.reviewInProgressCount;
      state.reviewedCount = initialState.reviewedCount;
      state.currentPage = initialState.currentPage;
      state.searchQuery = initialState.searchQuery;
      state.selectedStatusFilter = initialState.selectedStatusFilter;
      state.selectedReviewFilter = initialState.selectedReviewFilter;
    },
  },
});

export const { actions: projectsActions } = slice;

export const useProjectSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectSaga });
  return { actions: slice.actions };
};
