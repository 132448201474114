import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { request } from 'app/common/utils/request';
import { projectsActions as actions } from '.';
import { commonActions } from 'app/common/slices/errorBoundarySlice';

export function* getProjects({ payload }) {
  const { search, items, page, statusFilter, reviewStatus } = payload;
  yield delay(500);

  let requestURL = `${process.env.REACT_APP_API_BASE_URL}/project?page=${page}&items=${items}`;

  if (search && search.trim().length > 0) {
    requestURL += `&search=${encodeURIComponent(search)}&page=${page}`;
  }
  if (statusFilter) {
    requestURL += `&status=${statusFilter}`;
  }
  if (reviewStatus) {
    requestURL += `&reviewStatus=${reviewStatus}`;
  }

  try {
    const projectData = yield call(request, requestURL);
    yield put(actions.gotProjects(projectData));
    yield put(actions.removeLoader());
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* projectSaga() {
  yield takeLatest(actions.getProjects.type, getProjects);
}
