import React, { useCallback, useState } from 'react';
import { ReactTags } from 'react-tag-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useModalManagerSlice } from 'app/common/components/Modals/ModalManager/modalSlice';
import { useReviewSlice } from '../initiateReviewSlice';
import { request } from 'app/common/utils/request';
import { selectReview } from 'app/pages/Review/reviewSlice/selectors';
import 'app/common/components/ReactTagInput/style.css';
import '../InitiateReview/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const InitiateReview = props => {
  const fieldObj = {
    PGOwner: 'PG SPOC',
    AgileOwner: 'Process SPOC',
    TagOwner: 'TAG SPOC',
    ProjectOwner: 'Project SPOC',
  };
  const [startDate, setStartDate] = useState(
    props.data?.editData?.startDate &&
      moment(props.data?.editData.startDate).format('YYYY-MM-DD'),
  );
  const fields = Object.keys(fieldObj);
  const [validationError, setValidationError] = useState('');
  const [assessmentDetails, setAssessmentDetails] = useState(() => {
    const initialState = { trackName: '' };
    fields.forEach(field => {
      initialState[field] = {
        selectedEmail: [],
        suggestedEmails: [],
      };
    });
    if (props?.data?.isEdit && props?.data?.editData) {
      const { trackName, agileOwner, tagOwner, projectOwner, pgOwner } =
        props.data?.editData;
      return {
        ...initialState,
        trackName: trackName || '',
        AgileOwner: {
          selectedEmail: agileOwner
            ? agileOwner.map(data => ({ value: data.id, label: data.username }))
            : [],
          suggestedEmails: [],
        },
        TagOwner: {
          selectedEmail: tagOwner
            ? tagOwner.map(data => ({ value: data.id, label: data.username }))
            : [],
          suggestedEmails: [],
        },
        ProjectOwner: {
          selectedEmail: projectOwner
            ? projectOwner.map(data => ({
                value: data.id,
                label: data.username,
              }))
            : [],
          suggestedEmails: [],
        },
        PGOwner: {
          selectedEmail: pgOwner
            ? pgOwner.map(data => ({
                value: data.id,
                label: data.username,
              }))
            : [],
          suggestedEmails: [],
        },
      };
    }
    return initialState;
  });
  const reviewData = useSelector(selectReview);

  const dispatch = useDispatch();
  const { actions: modalManagerActions } = useModalManagerSlice();
  const { actions: reviewActions } = useReviewSlice();

  const handleInputChange = (field, value) => {
    setAssessmentDetails(prevDetails => ({
      ...prevDetails,
      trackName: value,
    }));
  };

  const initateTrack = () => {
    const flattenedDetails = {
      account: {
        _id: reviewData?.projectAccountDetails?.id,
        accountName: reviewData?.projectAccountDetails?.accountName,
      },
      businessUnit: {
        _id: reviewData?.projectAccountDetails?.businessUnitId,
        businessUnitName: reviewData?.projectAccountDetails?.businessUnitName,
      },
      project: {
        _id: reviewData?.selectedProject?.id,
        projectName: reviewData?.selectedProject?.option,
      },
      pgOwner: assessmentDetails.PGOwner.selectedEmail.map(
        email => email.value,
      ),
      agileOwner: assessmentDetails.AgileOwner.selectedEmail.map(
        email => email.value,
      ),
      tagOwner: assessmentDetails.TagOwner.selectedEmail.map(
        email => email.value,
      ),
      projectOwner: assessmentDetails.ProjectOwner.selectedEmail.map(
        email => email.value,
      ),
      trackName: assessmentDetails.trackName,
      startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
    };

    if (!flattenedDetails.trackName.trim()) {
      setValidationError('This field is required');
      return;
    }

    if (props?.data?.isEdit && props?.data?.editData) {
      dispatch(
        reviewActions.updateTrack({
          trackId: props.data.editData.id,
          data: flattenedDetails,
        }),
      );
    } else dispatch(reviewActions.createTrack(flattenedDetails));

    dispatch(modalManagerActions.closePopUp());
    setValidationError('');
  };

  const onDelete = useCallback((tagIndex, field) => {
    setAssessmentDetails(prevDetails => ({
      ...prevDetails,
      [field]: {
        ...prevDetails[field],
        selectedEmail: prevDetails[field]['selectedEmail'].filter(
          (_, i) => i !== tagIndex,
        ),
      },
    }));
  }, []);

  const onInput = (searchStr, field) => {
    if (searchStr.length === 0 && searchStr.trim().length === 0) {
      return false;
    } else if (searchStr.length < 4 && searchStr.trim().length < 4) {
      return false;
    }

    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL).then(userData => {
      const userDataNew = userData.map(data => ({
        value: data.id,
        label: data.username,
      }));

      setAssessmentDetails(prevDetails => ({
        ...prevDetails,
        [field]: {
          ...prevDetails[field],
          suggestedEmails: userDataNew,
        },
      }));
    });
  };
  const onAdd = useCallback((newTag, field) => {
    setAssessmentDetails(prevDetails => {
      const updatedField = {
        ...prevDetails[field],
        selectedEmail: [...prevDetails[field].selectedEmail, newTag],
        suggestedEmails: [],
      };

      return {
        ...prevDetails,
        [field]: updatedField,
      };
    });
  }, []);

  return (
    <div className="flex flex-col w-[400px]">
      <label className="mt-2 ml-2.5 mb-0">Review Type*</label>
      <input
        type="text"
        className="drp-1 ml-2.5 pl-2.5 relative px-4 py-3.5 rounded-xl border-solid border border-[#737791] focus:outline-none focus:border-[#323334] w-full md:w-[388px] lg:w-[388px]"
        onChange={e => handleInputChange('trackName', e.target.value)}
        placeholder="Enter Review Type"
        value={assessmentDetails.trackName}
      />
      {validationError && (
        <div className="text-red-500 ml-2.5">{validationError}</div>
      )}
      {fields.map((field, index) => {
        return (
          <div key={index} className="flex flex-col mx-2">
            <label className="mt-8 mb-0 text-{15px}">{fieldObj[field]}</label>
            <ReactTags
              selected={assessmentDetails[field].selectedEmail}
              suggestions={assessmentDetails[field].suggestedEmails}
              onAdd={data => onAdd(data, field)}
              onDelete={data => onDelete(data, field)}
              onInput={searchStr => onInput(searchStr, field)}
              noOptionsText="No matching emails"
              minQueryLength={4}
              placeholderText={
                assessmentDetails[field].selectedEmail.length > 0
                  ? ''
                  : `Enter ${fieldObj[field]} Email`
              }
            />
          </div>
        );
      })}
      <div className="startDateContainer">
        <label className="mt-8 ml-2.5 mb-0">Start Date</label>
        <DatePicker
          dateFormat="dd-MMM-yyyy"
          placeholderText="DD - MM - YYYY"
          className="drp-1 ml-2.5  relative   border-solid border border-[#b1b2b5] focus:outline-none  w-full md:w-[388px] lg:w-[388px] "
          selected={startDate}
          onChange={date => setStartDate(date)}
          isClearable
        />
      </div>
      <div className="flex space-x-6 justify-end mt-10 mb-5">
        <button
          className="px-10 py-4 rounded-lg border border-solid border-1 border-[#de1186] text-[#de1186]"
          onClick={() => dispatch(modalManagerActions.closePopUp())}
        >
          CANCEL
        </button>
        <button
          className="py-4 px-12 rounded-xl font-medium text-2xl border border-[#de1186] text-[#fff] bg-[#de1186] hover:text-[#fff] hover:border-[#de1186] "
          onClick={initateTrack}
        >
          {props?.data?.isEdit ? 'UPDATE' : 'ADD'}
        </button>
      </div>
    </div>
  );
};

export default InitiateReview;
