import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { request } from 'app/common/utils/request';
import { reviewActions as actions, reviewActions } from '.';
import { commonActions } from '../../../common/slices/errorBoundarySlice/index';

export function* getReviewCategory() {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/businessunit`;

  try {
    const review = yield call(request, requestURL);
    yield put(actions.gotReviewCategory(review));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotReviewError('CATEGORIES NOT FOUND'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotReviewError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotReviewError('RESPONSE ERROR'));
    // }
  }
}

export function* getBusinessUnitDetails(action) {
  yield delay(500);
  const businessUnitId = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/businessunit/${businessUnitId}`;
  try {
    const businessUnitDetails = yield call(request, requestURL);
    yield put(
      actions.gotBusinessUnitDetails({
        id: businessUnitId,
        details: businessUnitDetails,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(
    //     actions.gotBusinessUnitDetailsError('Business Unit Details not found'),
    //   );
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotBusinessUnitDetailsError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotBusinessUnitDetailsError('RESPONSE ERROR'));
    // }
  }
}

export function* getProjectAccountDetails(action) {
  yield delay(500);
  const projectAccountId = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/businessUnit/account/${projectAccountId}`;
  try {
    const projectAccountDetails = yield call(request, requestURL);
    yield put(
      actions.gotProjectAccountDetails({
        id: projectAccountId,
        details: projectAccountDetails,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(
    //     actions.gotProjectAccountDetailsError(
    //       'Project Account Details not found',
    //     ),
    //   );
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotProjectAccountDetailsError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotProjectAccountDetailsError('RESPONSE ERROR'));
    // }
  }
}

export function* getProjectReviewDetails(action) {
  yield delay(500);
  const selectedProject = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/tracks/${selectedProject.id}`;
  try {
    const projectReviewDetails = yield call(request, requestURL);
    yield put(
      actions.gotProjectReviewDetails({
        selectedProject: selectedProject,
        details: projectReviewDetails,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(
    //     actions.gotProjectReviewDetailsError(
    //       'Project Account Details not found',
    //     ),
    //   );
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotProjectReviewDetailsError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotProjectReviewDetailsError('RESPONSE ERROR'));
    // }
  }
}

export function* getProjectCategoryDetails(action) {
  yield delay(500);
  const params = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/review/${params.projectId}/${params.reviewDate}`;
  try {
    const projectReviewDetails = yield call(request, requestURL);
    yield put(
      actions.gotProjectCategoryDetails({
        id: params.projectId,
        details: projectReviewDetails,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(
    //     actions.gotProjectCategoryDetailsError(
    //       'Project Account Details not found',
    //     ),
    //   );
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotProjectCategoryDetailsError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotProjectCategoryDetailsError('RESPONSE ERROR'));
    // }
  }
}
export function* deleteReviewsTrack(action) {
  yield delay(500);
  const { projectId, trackId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/track/${trackId}`;
  try {
    yield put(actions.setLoader());
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(
      actions.reviewTrackDeleted({
        projectId: projectId,
        trackId: trackId,
      }),
    );
    toast.success('Track Deleted');
  } catch (err) {
    yield put(
      commonActions.setErrorData({
        ...JSON.parse(JSON.stringify(err)),
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* getAddCategoryReview(action) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/category`;
  try {
    const categories = yield call(request, requestURL);
    yield put(actions.gotReviewCategorys(categories));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotReviewCategorysError('CATEGORIES NOT FOUND'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotReviewCategorysError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotReviewCategorysError('RESPONSE ERROR'));
    // }
  }
}

export function* getAssessmentDetails(action) {
  //
  yield delay(500);
  const id = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/track/${id}`;
  try {
    const assessmentDetails = yield call(request, requestURL);
    yield put(
      actions.gotAssessmentData({
        id: id,
        assessmentDetails,
      }),
    );
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotAssessmentDataError('Assessment Details not found'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotAssessmentDataError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotAssessmentDataError('RESPONSE ERROR'));
    // }
  }
}

export function* updateAssessment(action) {
  yield delay(500);
  const id = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/track/${id.trackId}`;
  try {
    const updatedTrackData = yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify(id.data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(reviewActions.updateAssessmentList(updatedTrackData));
    if (id.msg) {
      toast.success(id.msg);
    } else {
      toast.success('Project Review has been submitted');
    }
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
    // if (err.response?.status === 404) {
    //   yield put(actions.gotAssessmentDataError('Assessment Details not found'));
    // } else if (err.message === 'Failed to fetch') {
    //   yield put(actions.gotAssessmentDataError('RATE LIMIT'));
    // } else {
    //   yield put(actions.gotAssessmentDataError('RESPONSE ERROR'));
    // }
  }
}

export function* generateRecommendation(action) {
  yield delay(500);
  const { trackId, assessmentId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation/create/${trackId}/${assessmentId}`;
  try {
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.recommendationGenerated(response));
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.recommendationError('NOT FOUND'));
      toast.error('Something went wrong');
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.recommendationError('RATE LIMIT'));
      toast.error('Something went wrong');
    } else {
      yield put(actions.recommendationError('RESPONSE ERROR'));
      toast.error('Something went wrong');
    }
  }
}

export function* getRecommendations(action) {
  yield delay(500);
  const trackId = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation?trackId=${trackId}`;
  try {
    const response = yield call(request, requestURL);
    yield put(actions.gotRecommendations(response));
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* saveRecommendationData(action) {
  yield delay(500);
  const { trackId, recommendationId, recommendationData } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation/${trackId}/${recommendationId}`;
  try {
    const response = yield call(request, requestURL, {
      method: 'PATCH',
      body: JSON.stringify(recommendationData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.gotRecommendationData(response));
    toast.success('Recommendation saved.');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}
export function* deleteRecommendation(action) {
  const { trackId, id } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation/${trackId}?trackRecId=${id}`;

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });
    yield put(actions.recommendationDeleted({ trackId, id }));
    toast.success('Recommendation deleted successfully.');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* deleteRecommendationData(action) {
  const { trackId, type, idToDelete, assmntRecommendationData, recommTableId } =
    action.payload;
  let queryParams = '';
  let successMessage = '';
  switch (type) {
    case 'parameter':
      queryParams = `trackRecParamId=${idToDelete}`;
      successMessage = 'Parameter deleted successfully.';
      break;
    case 'keyfindings':
      queryParams = `keyFindingId=${idToDelete}`;
      successMessage = 'Keyfinding deleted successfully.';
      break;
    case 'recommendations':
      queryParams = `recommendationId=${idToDelete}`;
      successMessage = 'Recommendation deleted successfully.';
      break;
    default:
      break;
  }

  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation/${trackId}?${queryParams}`;

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });

    switch (type) {
      // case 'parameter':
      //   yield put(actions.parameterDeleted({ trackId, trackRecParamId }));
      //   yield put(actions.gotRecommendations(response));
      //   break;
      case 'keyfindings':
        yield put(
          actions.keyfindingsDeleted({
            // trackId,
            idToDelete,
            assmntRecommendationData,
            recommTableId,
          }),
        );
        // yield put(actions.gotRecommendations(response));
        break;
      case 'parameter':
      case 'recommendations':
        yield put(
          actions.parameterRecomendationDeleted({
            // trackId,
            idToDelete,
            assmntRecommendationData,
            recommTableId,
            type,
          }),
        );
        // yield put(yield put(actions.gotRecommendations(response)));
        break;
      default:
        break;
    }

    toast.success(successMessage);
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* saveActionItem(action) {
  yield delay(500);
  const { trackRecId, data } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/actionitem/${trackRecId}`;
  try {
    const response = yield call(request, requestURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    yield put(actions.actionItemSaved({ trackRecId, response }));
    toast.success('Action Item saved successfully.');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* deleteActionItem(action) {
  const { trackRecId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/actionitem/${trackRecId}`;

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });

    yield put(actions.actionItemDeleted({ trackRecId }));

    toast.success('Action Item deleted successfully.');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* deleteActionRow(action) {
  const { trackRecId, actionItemId } = action.payload;
  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/actionitem/${trackRecId}?actionItemId=${actionItemId}`;

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    });

    yield put(actions.actionItemRowDeleted({ trackRecId, actionItemId }));

    toast.success('Actionitem row deleted successfully.');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* sendReport(action) {
  const { trackId, to, cc, bcc, file, mailContent } = action.payload;
  const formData = new FormData();
  formData.append('file', file);
  // formData.append('userIds', userIds);
  to.forEach(to => formData.append('to[]', to));
  cc.forEach(cc => formData.append('cc[]', cc));
  bcc.forEach(bcc => formData.append('bcc[]', bcc));
  formData.append('mailContent', mailContent);

  const requestURL = `${process.env.REACT_APP_API_BASE_URL}/recommendation/sendReport/${trackId}`;

  try {
    const assessmentDetails = yield call(request, requestURL, {
      method: 'POST',
      body: formData,

      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
    });

    yield put(actions.Reportsent({ id: trackId, assessmentDetails }));
    toast.success('Report has been Sent Successfully');
  } catch (err) {
    yield put(
      commonActions.setError({
        ...JSON.parse(JSON.stringify(err)),
        errMsg: err.message,
      }),
    );
    yield put(actions.removeLoader());
  }
}

export function* reverseDataSaved() {}

export function* clearProjectAccountDetails() {}

export function* reviewSaga() {
  yield takeLatest(actions.getReviewCategory, getReviewCategory);
  yield takeLatest(actions.getBusinessUnitDetails, getBusinessUnitDetails);
  yield takeLatest(actions.deleteReviewsTrack, deleteReviewsTrack);
  yield takeLatest(actions.getProjectAccountDetails, getProjectAccountDetails);
  yield takeLatest(actions.getProjectReviewDetails, getProjectReviewDetails);
  yield takeLatest(actions.updateAssessment, updateAssessment);
  yield takeLatest(
    actions.getProjectCategoryDetails,
    getProjectCategoryDetails,
  );
  yield takeLatest(actions.getReviewCategorys, getAddCategoryReview);
  yield takeLatest(actions.getTrackAssessmentData, getAssessmentDetails);
  yield takeLatest(
    actions.clearProjectAccountDetails,
    clearProjectAccountDetails,
  );
  yield takeLatest(actions.generateRecommendation, generateRecommendation);
  yield takeLatest(actions.getRecommendations, getRecommendations);
  yield takeLatest(actions.getRecommendationData, saveRecommendationData);
  yield takeLatest(actions.deleteRecommendation, deleteRecommendation);
  yield takeLatest(actions.deleteRecommendationData, deleteRecommendationData);
  yield takeLatest(actions.saveActionItem, saveActionItem);
  yield takeLatest(actions.deleteActionItem, deleteActionItem);
  yield takeLatest(actions.deleteActionRow, deleteActionRow);
  yield takeLatest(actions.sendReport, sendReport);
  yield takeLatest(actions.reverseDataSaved, reverseDataSaved);

  // yield takeLatest(actions.deleteKeyfindings, deleteRecommendationData);
  // yield takeLatest(
  //   actions.deleteRecomendationParameter,
  //   deleteRecommendationData,
  // );
}
