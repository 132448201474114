import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactTags } from 'react-tag-autocomplete';
import { cloneDeep, debounce } from 'lodash';
import DatePicker from 'react-datepicker';
import { useProjectReviewSlice } from '../AssessmentPage/assessmentSlice';
import FormAccordion from 'app/common/components/FormAccordion';
import Button from 'app/common/components/Button';
import Breadcrumb from 'app/common/components/Breadcrumb';
import Loader from 'app/common/components/Loader';
import { LoaderContainer } from 'app/pages/Setup/style';
import { selectProjectReview } from '../AssessmentPage/assessmentSlice/selectors';
import Input from 'app/common/components/Input/input';
import { request } from 'app/common/utils/request';
import { leftArrow } from 'app/assets/Common/commonImages';
import { infoImg } from 'app/assets/Common/commonImages';
import Permission from 'app/common/components/Permission/permission';
import { USER_ROLES } from 'app/common/services/permission/permissionConstant';
import 'app/common/components/ReactTagInput/style.css';
import { useSkillSlice } from '../../../../Skill/skillSlice';
import { SkillSelector } from '../../../../Skill/skillSlice/selector';

const AssessmentHandler = () => {
  const [formData, setFormData] = useState({});
  const [errorMessages, setErrorMessage] = useState({});
  const [assessmentDetails, setAssessmentDetails] = useState({
    attendees: [],
    techStack: [],
    suggestions: [],
    reviewFor: '',
    notes: '',
    startDate: '',
    endDate: '',
  });
  const [suggestionsTechStack, setSuggestionsTechStack] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const params = useParams();
  const { actions } = useProjectReviewSlice();
  const { actions: skillAction } = useSkillSlice();
  const projectReview = useSelector(selectProjectReview);
  const permissions = useSelector(SkillSelector);
  const skillListing = permissions?.skillData;

  const updateFormData = data => {
    const updatedData = { ...formData, parameters: data };
    setFormData(updatedData);
  };

  useEffect(() => {
    dispatch(skillAction.getSkill({}));
  }, [dispatch]);

  useEffect(() => {
    if (!skillListing || skillListing.length === 0) {
      return;
    }
    // Filter and format skills with 'approved' status
    const approvedSkills = skillListing?.filter(
      skill => skill.status === 'approved',
    );
    const formattedSkills = approvedSkills?.map(skill => ({
      value: skill.name,
      label: skill.name,
    }));

    setSuggestionsTechStack(formattedSkills);
  }, [skillListing]);

  // Handle adding tags
  const onAddTechStack = (data, type) => {
    if (type === 'tech-stack') {
      setAssessmentDetails(prevState => ({
        ...prevState,
        techStack: [...prevState.techStack, data],
      }));
    }
  };

  // Handle deleting tags
  const onDeleteTechStack = (index, type) => {
    if (type === 'tech-stack') {
      setAssessmentDetails(prevState => {
        const updatedTechStack = prevState.techStack?.filter(
          (_, idx) => idx !== index,
        );
        return { ...prevState, techStack: updatedTechStack };
      });
    }
  };

  useEffect(() => {
    if (params?.assessmentId) {
      dispatch(
        actions.getProjectReview({
          assessmentId: params?.assessmentId,
        }),
      );
    }
  }, [actions, dispatch, params?.assessmentId]);

  useEffect(() => {
    if (projectReview.assessmentId) {
      dispatch(actions.clearAssessmentId());
    }
  }, [actions, dispatch, projectReview.assessmentId]);

  const { loading, assessmentComplete } = projectReview;

  useEffect(() => {
    if (projectReview?.assessment?.id === params?.assessmentId) {
      setFormData(projectReview?.assessment?.category);
    }
  }, [
    params?.assessmentId,
    projectReview?.assessment?.category,
    projectReview?.assessment?.id,
  ]);

  useEffect(() => {
    if (projectReview?.assessment) {
      setAssessmentDetails({
        attendees:
          projectReview?.assessment?.attendees?.map(attendee => ({
            value: attendee._id,
            label: attendee?.username,
          })) || [],
        techStack:
          projectReview?.assessment?.techStack?.map(techStack => ({
            value: techStack,
            label: techStack,
          })) || [],
        suggestions: [],
        reviewFor: projectReview?.assessment?.reviewFor || '',
        notes: projectReview?.assessment?.notes || '',
        startDate:
          projectReview?.assessment?.startDate ||
          projectReview?.assessment?.createdAt,
        endDate: projectReview?.assessment?.endDate,
      });
    }
  }, [params?.assessmentId, projectReview?.assessment]);

  useEffect(() => {
    if (assessmentComplete) {
      navigate(`/review/track/${projectReview?.assessment?.track?.id}`);
    }
  }, [assessmentComplete, navigate, projectReview?.assessment?.track?.id]);

  const projectNameWithTrack = `${projectReview?.assessment?.track?.project?.projectName} - ${projectReview?.assessment?.track?.trackName}`;
  const breadcrumbItems = [
    { text: 'Review', link: '/review' },
    {
      text: projectReview?.assessment?.track?.businessUnit?.businessUnitName,
      link: `/review/${projectReview?.assessment?.track?.businessUnit?._id}`,
    },
    {
      text: projectReview?.assessment?.track?.account?.accountName,
      link: `/review/account/${projectReview?.assessment?.track?.account?._id}`,
    },
    {
      text: projectNameWithTrack,
      link: `/review/track/${projectReview?.assessment?.track?.id}`,
    },
    {
      text: projectReview?.assessment?.category?.name,
    },
  ];

  const validateForm = (formIsValid, errors) => {
    setErrorMessage(prevErrors => {
      const updatedErrors = { ...prevErrors, ...errors };
      return updatedErrors;
    });

    if (formIsValid) {
      setErrorMessage({});
    }
    return formIsValid;
  };

  const handleSubmit = status => {
    let formIsValid = true;
    const errors = {};
    if (assessmentDetails.attendees.length === 0) {
      errors['attendees'] = 'Attendees is required';
      formIsValid = false;
    }
    if (
      assessmentDetails.reviewFor === '' ||
      assessmentDetails.reviewFor?.trim() === ''
    ) {
      errors['reviewFor'] = 'Reviewing For is required';
      formIsValid = false;
    }
    let updatedData = cloneDeep(formData);
    updatedData.reviewDate = updatedData?.reviewDate || state?.reviewDate;
    updatedData.reviewStatus = 'Completed';
    if (updatedData?.parameters) {
      updatedData?.parameters.forEach((parameter, index) => {
        parameter.criterias.forEach((criteria, paramIndex) => {
          if (!criteria.assessment) {
            criteria.assessment = { rating: 0, status: '' };
          }
          if (!criteria?.assessment?.skip) {
            criteria.assessment.rating =
              criteria.assessment.rating !== ''
                ? criteria.assessment.rating
                : 0; // rating set as 0 when Weightage of Criteria is set as N/A
            criteria.assessment.status =
              criteria.assessment.status !== ''
                ? criteria.assessment.status
                : ''; // status set as blank when Weightage of Criteria is set as N/A
          }
          if (
            !criteria?.assessment?.skip &&
            (!criteria.assessment.comment ||
              criteria.assessment.comment.trim() === '')
          ) {
            formIsValid = false;
            errors[`comment${index}_${paramIndex}`] = 'Comment is required';
          }
        });
      });
    }
    if (status !== 'DRAFT') {
      if (assessmentDetails?.startDate > assessmentDetails?.endDate) {
        toast.error('Start Date can not be greater than endDate');
        return;
      }
    }
    if (status !== 'DRAFT' && !validateForm(formIsValid, errors)) {
      toast.error('Please fill all mandatory fields');
      return;
    }
    setErrorMessage({});
    if (status !== 'DRAFT') {
      const userConfirmed = window.confirm('Are you sure you want to submit?');
      if (!userConfirmed) {
        return false;
      }
    }

    let finalAssessmentData = cloneDeep(projectReview.assessment);
    finalAssessmentData.attendees = assessmentDetails.attendees.map(
      ({ value }) => value,
    );
    finalAssessmentData.techStack = assessmentDetails.techStack.map(
      ({ value }) => value,
    );
    finalAssessmentData.notes = assessmentDetails.notes;
    finalAssessmentData.reviewFor = assessmentDetails.reviewFor;
    finalAssessmentData.startDate = assessmentDetails.startDate;
    finalAssessmentData.endDate = assessmentDetails.endDate;
    finalAssessmentData.category = { ...updatedData };

    if (status === 'DRAFT') {
      finalAssessmentData.status = 'IN_PROGRESS';
    } else {
      finalAssessmentData.status = 'COMPLETED';
    }
    dispatch(actions.updateProjectReview(finalAssessmentData));
  };

  const onAdd = useCallback(
    (newTag, type) => {
      if (type === 'attendees') {
        setAssessmentDetails({
          ...assessmentDetails,
          attendees: [...assessmentDetails.attendees, newTag],
          suggestions: [],
        });
      } else {
        setAssessmentDetails({
          ...assessmentDetails,
          techStack: [...assessmentDetails.techStack, newTag],
        });
      }
    },
    [assessmentDetails],
  );

  const onDelete = useCallback(
    (tagIndex, type) => {
      if (type === 'attendees') {
        setAssessmentDetails({
          ...assessmentDetails,
          attendees: assessmentDetails.attendees.filter(
            (_, i) => i !== tagIndex,
          ),
        });
      } else {
        setAssessmentDetails({
          ...assessmentDetails,
          techStack: assessmentDetails.techStack.filter(
            (_, i) => i !== tagIndex,
          ),
        });
      }
    },
    [assessmentDetails],
  );

  const searchUsers = searchStr => {
    const requestURL = `${process.env.REACT_APP_API_BASE_URL}/user/search?queryString=${searchStr}`;
    request(requestURL)
      .then(userData => {
        let userDataNew = userData.map(data => {
          return { value: data.id, label: data?.username };
        });
        setAssessmentDetails({
          ...assessmentDetails,
          suggestions: userDataNew,
        });
      })
      .catch(error => {});
  };

  const debouncedSearch = debounce(searchUsers, 500); // 500 milliseconds debounce delay

  const onInput = searchStr => {
    if (searchStr.length === 0 && searchStr.trim().length === 0) {
      return false;
    } else if (searchStr.length < 4 && searchStr.trim().length < 4) {
      return false;
    }
    debouncedSearch(searchStr);
  };

  const handleBackBtnClick = () => {
    try {
      navigate(`/review/track/${projectReview?.assessment?.track?.id}`);
    } catch (e) {}
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {projectReview?.assessment?.category ? (
            <>
              <div className="p-[32px]">
                <div className="mb-8">
                  <div className="flex">
                    <img
                      src={leftArrow}
                      alt="back"
                      className="cursor-pointer h-8 mt-2 mr-2 -ml-1.5 filter grayscale"
                      onClick={handleBackBtnClick}
                    />
                    <h2 className="breadcrumb-heading text-[#151D48] text-[28px] mb-4 font-semibold">
                      {projectReview?.assessment?.category?.name}
                    </h2>
                  </div>
                  <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="mt-8 px-8 py-8 mb-6 border-b border-[#e2e2e2] bg-[#ECF1F9] pb-4 rounded-2xl">
                  <div>
                    <label className={`label-name`}>{'Attendees *'}</label>
                    <ReactTags
                      selected={assessmentDetails?.attendees}
                      suggestions={assessmentDetails?.suggestions}
                      onAdd={data => onAdd(data, 'attendees')}
                      onDelete={data => onDelete(data, 'attendees')}
                      onInput={onInput}
                      noOptionsText="No matching emails"
                      minQueryLength={4}
                      placeholderText={
                        assessmentDetails?.attendees?.length > 0
                          ? ''
                          : 'Enter User Email'
                      }
                      isDisabled={
                        projectReview?.assessment?.status === 'COMPLETED' &&
                        !state?.edit
                          ? 'true'
                          : ''
                      }
                    />
                    {errorMessages['attendees'] && (
                      <span className="text-red-500 text-lg">
                        {errorMessages['attendees']}
                      </span>
                    )}
                  </div>
                  <div className="pt-3 pb-3">
                    <label className={`label-name`}>{'Tech Stack'}</label>
                    <div>
                      <ReactTags
                        selected={assessmentDetails?.techStack}
                        suggestions={suggestionsTechStack}
                        onAdd={data => onAddTechStack(data, 'tech-stack')}
                        onDelete={data => onDeleteTechStack(data, 'tech-stack')}
                        allowNew
                        isDisabled={
                          projectReview?.assessment?.status === 'COMPLETED' &&
                          !state?.edit
                            ? 'true'
                            : ''
                        }
                        placeholderText={
                          assessmentDetails?.techStack?.length > 0
                            ? ''
                            : 'Enter Tech Stack'
                        }
                      />
                    </div>
                  </div>
                  <div className="pt-3 pb-3">
                    <div className="flex">
                      <label className={`label-name`}>{'Review Type *'}</label>
                      <div className="mt-[4px]">
                        <span className="relative group">
                          <img
                            src={infoImg}
                            alt="attendees"
                            className="cursor-pointer	h-[22px]"
                          />
                          <div className="z-10 table-auto border border-solid border-1 border-[#f0f4f7] rounded-[4px] absolute !hidden bottom-2 whitespace-nowrap rounded text-black opacity-0 transition group-hover:opacity-100 top-[0px] translate-x-10 group-hover:!block">
                            <p>
                              (eg: initial review, frontend review, backend
                              review)
                            </p>
                          </div>
                        </span>
                      </div>
                    </div>
                    <Input
                      label="Review Type"
                      placeholder="Reviewing For"
                      type="text"
                      name="reviewing-for"
                      value={assessmentDetails?.reviewFor}
                      className={`w-full py-3.5 px-3.5 resize-none rounded-2xl border-solid border border-[#737791] outline-none  `}
                      onChange={event => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          reviewFor: event.target.value,
                        });
                      }}
                      disabled={
                        projectReview?.assessment?.status === 'COMPLETED' &&
                        !state?.edit
                          ? 'disabled'
                          : ''
                      }
                    ></Input>
                    {errorMessages['reviewFor'] && (
                      <span className="text-red-500 text-lg">
                        {errorMessages['reviewFor']}
                      </span>
                    )}
                  </div>
                  <div className="pt-3 pb-3">
                    <label className={`label-name`}>{'Start Date'}</label>
                    <DatePicker
                      dateFormat="dd-MMM-yyyy"
                      selected={
                        assessmentDetails?.startDate ||
                        projectReview?.assessment?.createdAt
                      }
                      onChange={dateVal => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          startDate: dateVal.toISOString(),
                        });
                      }}
                      // minDate={projectReview?.assessment?.createdAt}
                      placeholderText="DD - MM - YYYY"
                      disabled={
                        projectReview?.assessment?.status === 'COMPLETED' &&
                        !state?.edit
                          ? 'true'
                          : ''
                      }
                    />
                  </div>
                  <div className="pt-3 pb-3">
                    <label className={`label-name`}>{'End Date'}</label>
                    <DatePicker
                      dateFormat="dd-MMM-yyyy"
                      selected={assessmentDetails?.endDate}
                      onChange={dateVal => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          endDate: dateVal.toISOString(),
                        });
                      }}
                      minDate={assessmentDetails?.startDate}
                      placeholderText="DD - MM - YYYY"
                      disabled={
                        projectReview?.assessment?.status === 'COMPLETED' &&
                        !state?.edit
                          ? 'true'
                          : ''
                      }
                    />
                  </div>
                  <div className="pt-3 pb-3">
                    <label className={`label-name`}>{'Notes'}</label>
                    <textarea
                      name="reviewing-for"
                      rows={7}
                      value={assessmentDetails.notes}
                      className={`w-full py-2 px-3 rounded-2xl border-solid border-2 border-[#737791] outline-none `}
                      onChange={event => {
                        setAssessmentDetails({
                          ...assessmentDetails,
                          notes: event.target.value,
                        });
                      }}
                      disabled={
                        projectReview?.assessment?.status === 'COMPLETED' &&
                        !state?.edit
                          ? 'disabled'
                          : ''
                      }
                    />
                  </div>
                </div>
                {Object.keys(formData).length && (
                  <FormAccordion
                    updateFormData={updateFormData}
                    formData={formData}
                    formStatus={projectReview?.assessment?.status}
                    errorMessages={errorMessages}
                    setErrorMessage={setErrorMessage}
                    editState={state?.edit}
                  />
                )}
              </div>
              {projectReview?.assessment?.status === 'COMPLETED' &&
              !state?.edit ? null : (
                <Permission accessTo={USER_ROLES.ADMIN}>
                  <div className="float-right	mr-16">
                    <Button
                      handleClick={() => {
                        handleSubmit('DRAFT');
                      }}
                      name="Save As Draft"
                      className="text-[#de1186] py-4 px-12 font-medium text-xl rounded-xl mt-8 mr-4 border border-solid border-[#de1186]"
                    />
                    <Button
                      handleClick={() => handleSubmit()}
                      name="Submit"
                      className="border bg-[#f6007e] py-4 px-12 font-medium text-xl text-white rounded-xl mt-8 ml-4"
                    />
                  </div>
                </Permission>
              )}
            </>
          ) : (
            'No Data Found'
          )}
        </>
      )}
    </>
  );
};

export default AssessmentHandler;
