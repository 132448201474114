import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Sidebar from './common/components/Sidebar';
import Header from './common/components/Header';
import { Routes } from './routes/index';
import { useUserAuthSlice } from './common/slices/authslice';
import { AccessDeniedPage } from './common/components/AccessDenied';
import LoaderComponent from './common/components/Loader';
import { selectUser } from './common/slices/authslice/selectors';
import { isEmpty } from 'app/common/utils/helperFn';
import { API_CONSTANTS } from './common/constants/constants';
import { USER_ROLES } from './common/services';
import { commonActions } from 'app/common/slices/errorBoundarySlice/selectors';
import ModalManager from './common/components/Modals/ModalManager/ModalManager';
import ErrorBoundary from 'app/common/components/ErrorBoundary';
import Login from './common/components/login';
import styled from 'styled-components/macro';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from 'styles/global-styles';
import 'styles/tailwind.css';

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function App(state) {
  const { actions } = useUserAuthSlice();
  const dispatch = useDispatch();
  const userResp = useSelector(selectUser);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { loading } = userResp;
  const commonAuth = useSelector(commonActions);
  const {
    error: { responseBody },
  } = commonAuth;
  const url = `${process.env.REACT_APP_API_BASE_URL}${API_CONSTANTS.LOGIN_AUTH}`;
  if (isEmpty(userResp) || !isEmpty(userResp?.error)) {
    window.open(url, '_self');
  }
  useEffect(() => {
    dispatch(actions.getUser());
  }, [actions, dispatch]);

  if (
    userResp?.user?.role === USER_ROLES.NO_ACCESS &&
    userResp?.user?.skillRole === USER_ROLES.NO_ACCESS
  ) {
    return <AccessDeniedPage />;
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Project Health Dashboard"
        defaultTitle="Project Health Dashboard"
      >
        <meta name="description" content="Project Health Dashboard" />
      </Helmet>
      <ErrorBoundary>
        {loading ? (
          <LoadingWrapper>
            <LoaderComponent />
          </LoadingWrapper>
        ) : (
          <>
            {window.location.pathname === '/login' && !userResp.user.name ? (
              <Login />
            ) : (
              <div className="flex flex-col">
                <div className="flex pt-[80px]">
                  {userResp.user.name.length ||
                  responseBody?.code === 'Unknown Error' ? (
                    <>
                      <Header />
                      <Sidebar
                        setSidebarOpen={setSidebarOpen}
                        sidebarOpen={sidebarOpen}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`p-[32px] bg-[#FAFBFC]  ${
                      sidebarOpen
                        ? 'ml-[345px] w-[calc(100%-345px)]'
                        : 'ml-[100px] w-[calc(100%-100px)]'
                    }`}
                  >
                    <div className="bg-white rounded-[20px]">
                      <Routes />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </ErrorBoundary>

      <ModalManager />
      <ToastContainer />
      <GlobalStyle />
    </BrowserRouter>
  );
}
