import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { projectList } from './projectSlice/selector';
import { useProjectSlice } from 'app/pages/Projects/projectSlice/index';
import DashboardTable from 'app/common/components/Table/table';
import LoaderComponent from 'app/common/components/Loader';
import { LoaderContainer } from '../Setup/style';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';
import Dropdown from 'app/common/components/DropDown';
import {
  projectStatusOptions,
  REVIEW_STATUS,
} from 'app/common/constants/constants';

function Projects() {
  const dispatch = useDispatch();
  const { actions } = useProjectSlice();
  const projects = useSelector(projectList);
  const {
    projectsData,
    loading,
    totalCount,
    count,
    currentPage,
    searchQuery,
    selectedStatusFilter,
    selectedReviewFilter,
    activeCount,
    reviewInProgressCount,
    reviewedCount,
  } = projects;
  const itemsPerPage = 20;

  useEffect(() => {
    dispatch(
      actions.getProjects({
        page: currentPage,
        items: itemsPerPage,
        search: searchQuery,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
      }),
    );
  }, [
    dispatch,
    actions,
    itemsPerPage,
    selectedStatusFilter,
    selectedReviewFilter,
  ]);

  const searchProjects = searchStr => {
    dispatch(actions.setPage(1));
    dispatch(
      actions.getProjects({
        page: 1,
        items: itemsPerPage,
        search: searchStr,
        statusFilter: selectedStatusFilter,
        reviewStatus: selectedReviewFilter,
      }),
    );
  };

  const debouncedSearch = useCallback(debounce(searchProjects, 500), [
    selectedStatusFilter,
  ]);

  const handleSearchChange = event => {
    dispatch(actions.setSearchQuery({ query: event?.target?.value }));
    debouncedSearch(event?.target?.value);
  };

  const fetchData = () => {
    if (!loading && projectsData?.length < count) {
      dispatch(actions.setPage(currentPage + 1));
      dispatch(
        actions.getProjects({
          items: itemsPerPage,
          page: currentPage + 1,
          search: searchQuery,
          statusFilter: selectedStatusFilter,
          reviewStatus: selectedReviewFilter,
        }),
      );
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'projectName',
      text: 'Project Name',
      formatter: (cell, row) => (
        <Link
          to={`/review/account/${row.accountId}`}
          style={{ textDecoration: 'none' }}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'buName',
      text: 'BU',
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      formatter: cell => {
        const status = REVIEW_STATUS?.find(item => item.value === cell);
        return (
          <div
            className={`${
              status
                ? `border rounded-md w-fit py-1.5 px-4 text-center font-sans text-base font-normal ${status.className}`
                : ''
            }`}
          >
            {status ? status.label : ''}
          </div>
        );
      },
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      formatter: cell => {
        if (!cell) return ' - ';
        const date = new Date(cell);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      },
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      formatter: cell => {
        if (!cell) return ' - ';
        const date = new Date(cell);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      },
    },
  ];
  function handleSelects(value) {
    const data =
      projectStatusOptions?.find(option => option.label === value)?.value || '';
    if (data !== selectedStatusFilter) {
      setTimeout(() => {
        dispatch(actions.setPage(1));
        dispatch(actions.setProjectFilterStatus(data));
      }, 200);
    }
  }
  function handleReviewSelects(value) {
    const data =
      REVIEW_STATUS?.find(option => option.label === value)?.value || '';
    if (data !== selectedReviewFilter) {
      setTimeout(() => {
        dispatch(actions.setPage(1));
        dispatch(actions.setReviewFilterStatus(data));
      }, 200);
    }
  }
  const selectedOptionLabel =
    projectStatusOptions?.find(option => option.value === selectedStatusFilter)
      ?.label || 'All';
  const selectedReviewOptionLabel =
    REVIEW_STATUS?.find(option => option.value === selectedReviewFilter)
      ?.label || 'All';

  return (
    <>
      {!projectsData?.length && loading ? (
        <LoaderContainer>
          <LoaderComponent />
        </LoaderContainer>
      ) : (
        <div className="p-[32px]">
          <div className="flex justify-between pb-2">
            <div className="heading">
              <h3 className="text-[#151D48] text-[28px] font-semibold">
                Projects
              </h3>
              <span className="text-[#151D48] text-[16px] font-normal opacity-50">
                Projects - Order by newly added
              </span>
            </div>

            <div className="flex w-3/5 items-baseline">
              <div className="wrap-search rounded-[6px] w-full relative bg-[#F9FAFB] border-[#737791]">
                <input
                  type="text"
                  placeholder="Search Projects"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="border border-[#737791] w-full h-[40px] px-[12px] text-[#6B7280] bg-[#F9FAFB] border-1 rounded-[6px] focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div className="pb-7 flex items-baseline justify-between">
            <p className="text-[#151D48] text-[16px] backgroundStyling">
              Total: <span className="opacity-50">{totalCount},</span>
              {'  '} Active: <span className="opacity-50">{activeCount},</span>
              {'  '} Review In Progress:{' '}
              <span className="opacity-50">{reviewInProgressCount},</span>
              {'  '} Reviewed:{' '}
              <span className="opacity-50">{reviewedCount}</span>
            </p>

            <div className="flex gap-4">
              <Dropdown
                options={projectStatusOptions?.map(option => option.label)}
                onSelect={handleSelects}
                selectedOption={selectedOptionLabel}
                selectedOptionStyle={{ padding: '10px' }}
                className="drp-2 relative min-w-[150px] w-full border border-solid border-1 rounded-[4px] border-[#737791]"
                placeholder="Select Project"
                optionsClassName="custom-options1"
              />
              <Dropdown
                options={REVIEW_STATUS?.map(option => option.label)}
                onSelect={handleReviewSelects}
                selectedOption={selectedReviewOptionLabel}
                selectedOptionStyle={{ padding: '10px' }}
                className="drp-2 relative min-w-[150px] w-full border border-solid border-1 rounded-[4px] border-[#737791]"
                placeholder="Select Review Status"
                optionsClassName="custom-options1"
              />
            </div>
          </div>
          <div
            id="scrollableDiv"
            className="border-t-[1px] border-[#151D48] border-opacity-[0.1] pt-10"
          >
            <InfiniteScroll
              dataLength={projectsData?.length}
              next={fetchData}
              hasMore={projectsData?.length < count}
              scrollThreshold={0.8}
              height={'70vh'}
              scrollableTarget="scrollableDiv"
              loader={
                loading && (
                  <LoaderContainer height={'11vh'}>
                    <LoaderComponent />
                  </LoaderContainer>
                )
              }
            >
              {projectsData?.length ? (
                <DashboardTable
                  data={projectsData}
                  columns={columns}
                  classcategory={'tablereviewpage'}
                  className="sticky-header"
                />
              ) : (
                <p className="text-center">No Data Found</p>
              )}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
}

export default Projects;
