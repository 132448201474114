// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backgroundStyling {
  background-color: #f7f8f9;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.heading {
  h3 {
    margin: 0 0 10px 0;
  }
  span {
    line-height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Projects/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".backgroundStyling {\n  background-color: #f7f8f9;\n  border-radius: 10px;\n  width: fit-content;\n}\n.heading {\n  h3 {\n    margin: 0 0 10px 0;\n  }\n  span {\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
